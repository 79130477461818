@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
.App {
  text-align: center;
}

body {
  font-family: "Roboto Mono", monospace;
  font-weight: normal;
  background-color: #000;
  background-image: url("assets/images/bg.gif");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-container {
  margin: 5%;
  background-color: #000;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.header {
  background-color: #fff;
  padding: 25px 15px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav {
  display: flex;
  width: 100%;
  justify-content: space-between; 
}

.nav-left, .nav-right {
  list-style: none;
  display: flex;
  padding: 0; 
  margin: 0; 
}

.nav-left li, .nav-right li {
  margin: 0 30px; 
}

.header img {
  height: 80px;
}

.loading img {
  margin: 0;
}


.loading {
  display: flex; 
  align-items: center;
  justify-content: center; 
  height: 100vh;
}

.loading p {
  margin: 0;
  display: flex;
  align-items: center;
}

.loading p {
  font-size: 18px;
}
a {
  color: #000;
  text-decoration: none;
}

.inline-a {
  color: #fff;
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
}

h1 {
  font-weight: normal;
  font-size: 96px;
  color: #fff;
}

h2 {
  font-weight: normal;
  color: #fff;
}

footer {
  background-color: #fff;
}

button {
  font-family: "Roboto Mono", monospace;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  border: 2px solid #000;
  color: #fff;
  background: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  padding: 12px 20px;
  text-align:center;
  transition-duration: .15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

button:hover {
  background: #fff;
  color: #000;
}

.front-page-logo {
  margin-left: 20%;
}

.front-page-text {
  color: #fff;
  margin-top: 3%;
}

.contact-form-bg, .login-form-bg, .registration-form-bg, .addwebsite-form-bg {
  color: #fff;
  background-color: #000;
  height: 80.4vh;
}

.addwebsite-form-bg {
  height: 90.4vh;
}

.checkout-form-bg {
  width: 100%;
}
.contact-form, .login-form, .registration-form, .addwebsite-form {
  width: 70%;
  margin: 0% auto;
}

select {
  font-family: "Roboto Mono", monospace;
  background-color: #000;
  color: #fff;
}
.contact-form form, .login-form form, .registration-form form, .checkout-form form, .ForgotPassword form, .ResetPassword form{
 padding-top: 8%; 
}

.contact-form label, .login-form label, .registration-form label, .checkout-form label, .ForgotPassword label, .ResetPassword label{
  display: block;
}

.contact-form button, .login-form button, .registration-form button, .checkout-form button, .ForgotPassword button, .ResetPassword button{
  margin: auto;
  display: block;
}

.contact-form input, textarea, .login-form input, .registration-form input, .addwebsite-form input, .ForgotPassword input, .ResetPassword input {
  padding: 0.5%;
  margin: 1%;
  width: 30%;
}

input[type="checkbox" i] {
  accent-color: #1b1b1b;
}

.checkout-form button {
  margin-top: 3%;
}

.payment-seperation {
  margin: 0 auto;
  width: 90%;
  border-bottom: 1px #fff solid;
  margin-top: 10%;
}

footer {
  padding: 4px;
}

.card-element-container {
  width: 100%; /* Full width */
  max-width: 800px; /* Maximum width */
  margin: 0 auto; /* Center the container */
  border: 1px solid #ccc; /* Optional border */
  border-radius: 4px; /* Optional border radius */
  padding: 10px; /* Optional padding */
}


footer p {
  margin: 0px;
  padding: 0.15%;
}
footer a {
  font-size: 12px;
}

.PrivacyPolicy, .TermsOfService {
  padding: 1%;
  color: #fff;
  background-color: #000;
}

.PrivacyPolicy h1, .TermsOfService h1 {

  font-size: 30px;
}

.About, .Dashboard, .Settings, .loading, .UpdateEmail, .UpdatePassword, .Checkout, .Receipt, .ForgotPassword, .ResetPassword, .Blog {
  padding: 0.1px;
  color: #fff;
  background-color: #000;
  height: 80vh;
}

.About {
  height: 120vh;
}

.Blog {
  display: flex;
}

.Blog h1 {
  font-size: 68px;
  margin: 5%;
  width: 20%;
}

.blog-content {
  padding: 1%;
  border: #fff 1px solid;
  text-align: left;
  margin: 1%;
}

.blog-content p, pre {
  margin: 0;
}




.receipt-details {
  margin: 5% auto;
  width: 30%;
  border: 1px #fff solid;
}

.dns-instructions {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  background-color: #000;
  height: 110vh;
}

ul {
  list-style-type: none;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}


.questions {
  display: flex;
}

.question-block {
  margin: 0 2%;
  width: 30%;
}

.Dashboard, .Checkout{
  display: flex;
}

.website-buttons {
  left: 25%;
  bottom: 10px;
  position: absolute;
}

.cloudpanel-logo {
  height: 30px;
  transition: transform 0.3s;
  padding: 3%;
  border: solid 1px #fff;
  margin: 0% 20%;
}

.cloudpanel-logo:hover {
  position: relative;
  transform: scale(1.2);
  border: solid 3px #fff;
}

.instructions {
  padding-top: 1%;
  padding-bottom: 0.5%;
}
.settings-buttons{
  margin-top: 12%;
}
.Pricing {
  padding: 0.1px;
  color: #fff;
  background-color: #000;
  height: 95vh;
}

.login-form a {
  color: #fff;
}

.Pricing a, .instructions a, .dns-instructions a, .dashboard-websites a {
  color: #fff;
  text-decoration: underline;
}

.delete-link {
  display: none; /* Hide the delete link by default */
  margin-left: 8px; /* Add some space between the domain name and the delete link */
}

.website-container:hover .delete-link {
  display: inline; /* Show the delete link when hovering over the container */
}
.Pricing h1 {
  font-size: 48px;
}

ul {
  padding: 0;
}

.dashboard-websites li {
  margin: 20px 0;
}
.About h3 {
  margin: 5%;
}

.social-media-icons .linkedin,
.social-media-icons .github,
.social-media-icons .twitter{
  color:#000;
  transition-duration: 200ms;
  margin: 10px;
  
}

.social-media-icons .linkedin:hover,
.social-media-icons .github:hover,
.social-media-icons .twitter:hover{
  transform: scale(1.2);
  transition-duration: 200ms;
}

.pricing-card {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between; /* Space between children */
  border: 1px solid #fff;
  padding: 20px;
  margin: 10px;
  transition: transform 0.2s;
}
.pricing-card:hover, .pricing-card:hover h2, .pricing-card:hover .annual-price {
  color: #000;
  background-color: #fff;
  transform: scale(1.02);
}


.price {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: bold;
}

.total {
  left: 36%;
  bottom: 10px;
  position: absolute;
}

.annual-price {
  margin-top: 0;
  color: #acacac;
  font-size: 14px;
}

.features {
  list-style-type: none;
  padding: 0;
  flex-grow: 1;
}

.btn {
  background-color: #000000;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: auto; /* Push the button to the bottom */
}

.btn:hover {
  border: 1px solid #000;
}

/* PricingPage.css */
.pricing-page {
  text-align: center;
  padding: 40px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.dashboard-websites, .Cart {
  position: relative;
  margin: 20px;
  border: 1px #fff solid;
  width: 35%;
}

.user-info{
  width: 65%;
  margin: 20px auto;
}

.user-plan-info {
  border: 1px #fff solid;
  height: 75%;
}

.user-account-info {
  margin: 5%;
}

.plan-info-text {
  display: flex;
  justify-content: space-between;
  margin: 0 40px;
}

.dashboard-websites, h2 {
  color: #000;
  text-decoration: underline;
}

.job-description h2 {
  color: #000;
}

.job-description, p {
  font-size: 12px;
}

@keyframes slideDown {
  0% {
    top: -100px;  /* Start off-screen */
  }
  100% {
    top: 0; /* Slide to the top of the page */
  }
}

/* Add styles for the notification bar */
.notification-bar {
  position: fixed;
  top: -100px;  /* Initially hide above the screen */
  left: 0;
  width: 100%;
  background-color: #000;
  color: white;
  text-align: center;
  padding: 15px 0;
  font-size: 18px;
  z-index: 1000;
  animation: slideDown 0.5s ease-out forwards; /* Trigger the slide-down animation */
  align-items: center;
  padding: 15px 20px;
}

/* Style the close button */
.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-left: 15px;
}

.add-websites-header {
  color: #fff;
}

/* Add some padding to the content below to avoid overlap */
.content {
  margin-top: 60px; /* Adjust this based on the height of the notification bar */
}

.notification-bar a {
  color: #fff;
}

@media (max-width: 800px) {
  .header {
    padding: 10px;
  }
  .header ul {
    display: block;
  }
  .header img {
    display: none;
    height: 100%;
  }
  
  .header li {
    font-size: 12px;
    margin: 6%;
  }
  .home-container {
    margin-top: 13vh;
    margin-bottom: 13vh;
    width: 90%;
    background-color: #000;
    display: block;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  .home-container h1 {
    font-size: 46px;
    padding-top: 50px;
    margin: 0;
  }
  .home-container img {
    height: 70%;
    width: 70%;
  }

  .front-page-text {
    margin: 10%;
  }

  .Pricing {
    height: 100%;
  }

  .About {
    height: 100%;
  }

  .questions {
    display: block;
  }

  .question-block {
    margin: 0 4%;
    width: 95%;
  }
  .contact-form-bg {
    height: 90vh;
  }
  .contact-form {
    width: 95%;
  }

  .contact-form form {
    padding-top: 50%;
  }

  .contact-form input, textarea, .login-form input, .registration-form input, .addwebsite-form input, .ForgotPassword input, .ResetPassword input  {
    width: 80%
  }

  .Checkout {
    height: 110vh;
    display: block;
  }

  .Cart {
    height: 250px;
    width: 90%;
  }

  
}
